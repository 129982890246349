import React from "react";
import loadable from "@loadable/component";

const _module2Component = {
  "products-categories": loadable(() => import("components/products-categories")),
  "card": loadable(() => import("components/product-card")),
  "testimonial": loadable(() => import("components/testimonial")),
  "videoTestimonial": loadable(() => import("components/video-testimonail")),
  "twoColumns": loadable(() => import("components/two-columns")),
  "contactHero": loadable(() => import("components/contact-hero")),
  "fullTwoColumns": loadable(() => import("components/full-two-columns")),
  "heroSection": loadable(() => import("components/hero-section")),
  "heroes": loadable(() => import("components/heroes")),
  "ambassador": loadable(() => import("components/professional-chefs")),
  "halfMediaDescription": loadable(() => import("components/half-media-description")),
  "floatingHero": loadable(() => import("components/floating-hero")),
  "doubleSlider": loadable(() => import("components/double-slider")),
  "missionBanner": loadable(() => import("components/our-mission-banner")),
  "textAccordion": loadable(() => import("components/text-accordion")),
  "mediaTimelineAccordion": loadable(() => import("components/media-list-accordion")),
  "productDetailsAccordion": loadable(() => import("components/product-details-accordion")),
  "topArticles": loadable(() => import("components/top-articles")),
  "mediaTab": loadable(() => import("components/media-tab")),
  "collectionList": loadable(() => import("components/collection-list")),
  "kitchenware": loadable(() => import("components/kitchenware-card")),
  "product-details-accordion": loadable(() => import("components/product-details-accordion")),
  "productHighlights": loadable(() => import("components/product-highlights")),
  "bestProductsReviews": loadable(() => import("components/best-products-reviews")),
  "top-articles": loadable(() => import("components/top-articles")),
  "richText": loadable(() => import("components/rich-text-section")),
  "contentsTable": loadable(() => import("components/contents-table")),
  "author": loadable(() => import("components/author-comment")),
  "siteReviews": loadable(() => import("components/product-reviews")),
  "basicSlider": loadable(() => import("components/basic-slide")),
  "imageHotspots": loadable(() => import("components/olavs-pane-banner")),
};

const Module = ({ moduleData, type }) => {
  const RequestedModule = _module2Component[type];
  if (RequestedModule) return <RequestedModule data={moduleData} />;

  return (<span>{type}</span>);
};

export default Module;
