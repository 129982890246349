import React from "react";
import Module from "components/modules";

const RenderModules = (modules) => {
  if (!modules) return;
  return modules?.map((module) => {

    return (
      <React.Fragment
        key={module._key}>
        <Module
          type={module._type}
          moduleData={module} />
      </React.Fragment>
    );
  });
};

export default RenderModules;
